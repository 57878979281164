<template>
    <div class="table">
        <el-table :data="goodsList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column width="75px">
                <template #header>
                    <el-checkbox v-model="allchecked" @change="selectToatl">全选</el-checkbox>
                </template>
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="selectChange"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column width="120" align="center">
                <template #header>
                    <div class="edit">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                        @change="editInfo(scope.row, 1)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="商品ID" align="center">
                <template slot-scope="scope">
                    <span @click="copyGoodsId($event, scope.row.id)" style="border-bottom: 1px solid; cursor: pointer">{{
                        scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="grade" label="商品主图" width="100px" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px"
                        :src="scope.row.goods_cover_picture ? scope.row.goods_cover_picture : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="classify_name" label="商品分类" width="120" align="center">
                <template v-slot="{ row, $index }">
                    <el-tooltip :disabled="row.is_classify_name" :content="row.classify_name" placement="top"
                        effect="light">
                        <div :class="['classify_name', 'classify_name' + $index]">{{ row.classify_name }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="goods_name" label="商品名称" width="200" align="center"></el-table-column>
            <el-table-column width="120" align="center">
                <template #header>
                    <div class="edit">
                        <span>商品价格</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.goods_price"
                        @change="editInfo(scope.row, 2)"></el-input>
                </template>
            </el-table-column>
            <!--<el-table-column width="120" align="center">
        <template #header>
          <div class="edit">
            <span>商品原价</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input type="number" :min="0" size="small" v-model="scope.row.goods_prime_cost" @change="editInfo(scope.row, 4)"></el-input>
        </template>
      </el-table-column>-->
            <el-table-column width="120px" align="center">
                <template #header>
                    <div class="edit">
                        <span>SKU库存</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input :disabled="scope.row.is_spec || scope.row.is_zone_price" type="number" :min="0" size="small"
                        v-model="scope.row.goods_stock" @change="editInfo(scope.row, 3)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="sales" label="销量" width="100px" align="center"></el-table-column>
            <!--<el-table-column prop="status" label="商品状态" align="center"></el-table-column>-->
            <el-table-column width="120" align="center">
                <template #header>
                    <div class="edit">
                        <span>上/下架</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-button type="info" plain v-if="scope.row.is_putaway == 1" size="small"
                        @click="setPutaway(scope.row, scope.$index)">下架</el-button>
                    <el-button type="primary" v-else size="small"
                        @click="setPutaway(scope.row, scope.$index)">上架</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="210px" align="center" fixed="right">
                <template slot-scope="scope">
                    <div class="btn">
                        <el-button @click="actionsType(scope.row, 1)" type="text" size="small">编辑</el-button>
                        <el-button @click="actionsType(scope.row, 2, scope.$index)" type="text" size="small">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData">
            <div slot="batch">
                <el-checkbox v-model="allchecked" @change="selectToatl" style="margin-right: 20px">全选</el-checkbox>
                <el-select placeholder="批量操作" size="mini" v-model="batchActionId" style="margin-right: 20px">
                    <el-option v-for="item in batchAction" :key="item.val" :label="item.name" :value="item.val"></el-option>
                </el-select>
                <el-button size="mini" @click="handleBatchAction">确定</el-button>
            </div>
        </Paging>
    </div>
</template>

<script>
import clipboard from '@/util/clipBoard.js';
import Paging from '@/components/paging';
import config from '@/util/config';
const industryName = '/Community';//行业版本接口名称（用于更换重复接口）
export default {
    components: {
        Paging,
    },
    data () {
        return {
            allchecked: !1,
            batchActionId: '',
            freightList: [],
            id: [],
            freight_id: '',
            freight_price: '',
            freight_type: 0,
            classify_id: '',
            batchAction: [
                {
                    name: '删除',
                    val: 1,
                },
                {
                    name: '上架',
                    val: 2,
                },
                {
                    name: '下架',
                    val: 3,
                },
            ],
            goodsList: [],
            page: 1,
            rows: 10,
            total_number: 0,
            test_img: '',
        };
    },
    props: {
        content: {
            type: Object,
        },
        goodsClass: {
            type: Array,
        },
    },
    created () {
        let utils = this.$store.state.utils;
        if (utils.is_record) {
            this.page = utils.page;
            this.rows = utils.rows;
            utils.is_record = !1;
        }
        this.getGoodsList();
    },
    methods: {
        handleClassChange (val) {
            let arr = [];
            for (let i in val) {
                for (let y in val[i]) arr.push(val[i][y]);
            }
            this.classify_id = arr.join();
        },
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
                this.getGoodsList();
            } else {
                this.page = val;
                this.getGoodsList();
            }
        },
        //修改商品信息
        editInfo (row, type) {
            if (type == 1) {
                this.$axios.post(industryName + this.$api.goods.editInfo, {
                    id: row.id,
                    sort: row.sort,
                });
            } else if (type == 2) {
                this.$axios.post(industryName + this.$api.goods.editInfo, {
                    id: row.id,
                    goods_price: row.goods_price,
                });
            } else if (type == 3) {
                this.$axios.post(industryName + this.$api.goods.editInfo, {
                    id: row.id,
                    goods_stock: row.goods_stock,
                });
            } else if (type == 4) {
                this.$axios.post(industryName + this.$api.goods.editInfo, {
                    id: row.id,
                    goods_prime_cost: row.goods_prime_cost,
                });
            }
        },
        //上架/下架
        setPutaway (row, index) {
            this.$confirm(row.is_putaway == 1 ? '请确认是否下架' : '请确认是否上架', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(industryName + this.$api.goods.setPutaway, {
                        id: row.id,
                        is_putaway: row.is_putaway == 1 ? -1 : 1,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                message: row.is_putaway == 1 ? '商品已下架' : '商品已上架',
                                type: 'success',
                            });
                            this.getGoodsList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        //处理批量上架/下架
        handlePutaway (type) {
            this.$axios
                .post(industryName + this.$api.goods.setPutaway, {
                    id: this.id,
                    is_putaway: type == 2 ? 1 : 0,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: type == 2 ? '商品已上架' : '商品已下架',
                            type: 'success',
                        });
                        this.allchecked = !1;
                        //this.$parent.$parent.getGoodsStatus();
                        this.getGoodsList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //全选
        selectToatl (val) {
            if (val) {
                for (let i in this.goodsList) this.goodsList[i].checked = !0;
            } else {
                for (let i in this.goodsList) this.goodsList[i].checked = !1;
            }
        },
        //单选
        selectChange () {
            let checked = this.goodsList.filter(item => item.checked);
            if (!checked.length) this.allchecked = !1;
        },
        //批量操作
        handleBatchAction () {
            let goodsList = this.goodsList;
            this.id = [];
            for (let i in goodsList) {
                if (goodsList[i].checked) this.id.push(goodsList[i].id);
            }
            switch (this.batchActionId) {
                case 1:
                    //批量删除商品
                    if (!this.id.length) return this.$message.warning('请选择要删除的商品');
                    this.$confirm('请确认是否批量删除所选商品', '批量删除', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.$axios
                            .post(industryName + this.$api.goods.delGoods, {
                                id: this.id,
                            })
                            .then(res => {
                                if (res.code == 0) {
                                    this.getGoodsList();
                                    //this.$parent.$parent.getGoodsStatus();
                                    this.$message.success('删除成功');
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    });
                    break;
                case 2:
                    //批量上架
                    if (!this.id.length) return this.$message.warning('请选择要上架的商品');
                    this.handlePutaway(2);
                    break;
                case 3:
                    //批量下架
                    if (!this.id.length) return this.$message.warning('请选择要下架的商品');
                    this.handlePutaway(3);
                    break;
                default:
                    this.$message({
                        message: '请选择批量操作类型',
                        type: 'warning',
                    });
            }
        },
        getGoodsList () {
            this.content.page = this.page;
            this.content.rows = this.rows;
            this.$axios.post(industryName + this.$api.goods.list, this.content).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        list[i].checked = !1;
                        list[i].is_wxcode = !1;
                        list[i].wxcode = '';
                        list[i].is_classify_name = !0;
                    }
                    this.goodsList = list;
                    this.$nextTick(() => {
                        for (let i in list) {
                            let classify_name = document.querySelector('.classify_name' + i);
                            if (classify_name.scrollWidth > classify_name.offsetWidth) this.goodsList[i].is_classify_name = !1;
                        }
                    });
                    this.total_number = res.result.total_number;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                    });
                }
            });
        },
        copyGoodsId (e, id) {
            clipboard(e, id);
        },
        actionsType (row, type, i) {
            switch (type) {
                case 1:
                    //编辑
                    this.$router.push({
                        path: '/repair/goods/edit',
                        query: {
                            id: row.id,
                            page: this.page,
                            rows: this.rows,
                        },
                    });
                    break;
                case 2:
                    //删除商品
                    this.$confirm('确认删除此商品？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.$axios
                            .post(industryName + this.$api.goods.delGoods, {
                                id: row.id,
                            })
                            .then(res => {
                                if (res.code == 0) {
                                    let goodsList = [...this.goodsList];
                                    goodsList.splice(i, 1);
                                    this.total_number--;
                                    //this.$parent.$parent.getGoodsStatus();
                                    this.goodsList = goodsList;
                                    this.$message({
                                        message: '删除成功',
                                        type: 'success',
                                    });
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    });
                    break;
                case 4:
                    if (row.wxcode) {
                        setTimeout(() => {
                            this.goodsList[i].is_wxcode = !0;
                        }, 100);
                    } else {
                        this.$axios.post(industryName + this.$api.goods.wxCode, { id: row.id }).then(res => {
                            if (res.code == 0) {
                                this.goodsList[i].wxcode = config.baseurl + '/' + res.result;
                                this.goodsList[i].is_wxcode = !0;
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }
                    break;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.table {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
}

.freight {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin: 0;
    }

    .el-select,
    .el-input {
        width: 300px;
        margin-right: 10px;
    }

    p {
        color: #999;
    }
}

/deep/ .cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.el-table {

    //   width: calc(100% - 235px);
    // min-height:400px;
    /deep/ .el-input__inner {
        border-color: #fff;
    }

    /deep/ .el-input__inner:focus {
        border-color: #409eff;
    }

    .edit {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .classify_name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }
}
</style>
